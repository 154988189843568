import { css } from '@emotion/react'
import { theme } from '@dfds-ui/theme'
import { useTranslation } from 'react-i18next'

const CouldNotRetrieveData = () => {
  const { t } = useTranslation()
  return (
    <div
      css={css`
        color: ${theme.colors.status.alert};
      `}
    >
      {t('COULD-NOT-RETRIEVE-DATA')}
    </div>
  )
}

export default CouldNotRetrieveData
