import { setGlobalAppElement as setRoot } from '@dfds-ui/modal'

/**
 * @param {string} selector
 */
export function setGlobalAppElement() {
  if (typeof window !== 'undefined') {
    setRoot('#___gatsby')
  }
}
