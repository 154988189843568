import { SystemIcon } from '@dfds-ui/icons'
import { LinkButton } from '@dfds-ui/react-components'
import { theme } from '@dfds-ui/theme'
import { Text } from '@dfds-ui/typography'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  add,
  addDays,
  format,
  intervalToDuration,
  isSameDay,
  startOfToday,
  sub,
} from 'date-fns'
import { isEmpty } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatLocalizedDate } from '../../utils/dateAndTime'
import { FlexBox } from '../FlexBox'
import { useLocaleContext } from '../LocaleContext'
import CouldNotRetrieveData from './Error'

const WeekDayContainer = styled(FlexBox)`
  flex: 0 0 14.28%;
  text-align: center;
  justify-content: center;
  color: ${({ selected }) =>
    selected
      ? `${theme.colors.text.secondary.primary}`
      : `${theme.colors.text.dark.primary}`};
  border-style: solid;
  border-width: ${({ selected }) => (selected ? `0 0 2px 0` : `0 0 1px 0`)};
  border-color: ${({ selected }) =>
    selected
      ? `${theme.colors.text.secondary.primary}`
      : `${theme.colors.text.dark.disabled}`};
  cursor: pointer;
  span {
    margin: 10px auto;
  }
`
const Head = styled(FlexBox)`
  width: 100%;
  text-align: center;

  p {
    flex: 0 0 33%;
    font-size: 12px;
    font-weight: 700;
    margin: 5px 0;
  }
`

const ColumnHeader = styled(Text)`
  color: ${theme.colors.text.primary.primary};
`

const Sailing = styled(FlexBox)`
  width: 100%;
  text-align: center;
  background-color: ${({ grey }) =>
    grey ? theme.colors.surface.primary : 'none'};
  p {
    flex: 0 0 33%;
    margin: 0;
    padding: 8px 0;
    border-style: solid;
    border-width: 1px 0 0 0;
    border-color: ${theme.colors.divider.dark};
  }
`

const PaxTimeTableDirection = (props) => {
  const { t } = useTranslation()
  const {
    gateClosesXMinutesBeforeDeparture,
    schedule,
    startDate,
    selectedDate,
    nextSailingDate,
    selectedDateChanged,
  } = props

  const weekDays = []
  const selectedDay =
    intervalToDuration({
      start: startDate,
      end: selectedDate,
    }).days ?? 0

  const { locale } = useLocaleContext()
  for (let i = 0; i < 7; i++) {
    const date = addDays(startDate, i)
    weekDays.push(
      <WeekDayContainer
        selected={i === selectedDay}
        key={i}
        onClick={() => selectedDateChanged(addDays(startDate, i))}
      >
        <FlexBox directionColumn>
          <Text
            styledAs={'label'}
            css={css`
              margin-bottom: 0;
              text-transform: capitalize;
            `}
          >
            {formatLocalizedDate(date, 'EEEEEE', locale)
              .replace(/./g, '')
              .slice(0, 3)}
          </Text>
          <Text
            styledAs={'caption'}
            css={css`
              margin-bottom: 10px;
            `}
          >
            {format(date, 'dd')}
          </Text>
        </FlexBox>
      </WeekDayContainer>,
    )
  }

  const weekToShow = {}
  if (!isEmpty(schedule)) {
    for (let i = 0; i < 7; i++) {
      weekToShow[i + 1] = schedule.filter((sailing) =>
        isSameDay(sailing.departureTime, add(startDate, { days: i })),
      )
    }
  }

  const RenderDay = ({ day }) => {
    const departures = weekToShow[day + 1]
    if (schedule && !isEmpty(schedule) && !isEmpty(departures)) {
      const daySchedule = departures.map((sailing, i) => {
        return (
          <Sailing directionRow grey={i % 2} key={i}>
            <Text styledAs={'bodyInterface'}>
              {format(
                sub(sailing.departureTime, {
                  minutes: gateClosesXMinutesBeforeDeparture,
                }),
                'HH:mm',
              )}
            </Text>
            <Text styledAs={'bodyInterface'}>
              {format(sailing.departureTime, 'HH:mm')}
            </Text>
            <Text styledAs={'bodyInterface'}>
              {format(sailing.arrivalTime, 'HH:mm')}
            </Text>
          </Sailing>
        )
      })
      return (
        <FlexBox directionColumn>
          <Head justifySpaceAround>
            <ColumnHeader styledAs={'labelSmallBold'}>
              {t('GATE-CLOSES')}
            </ColumnHeader>
            <ColumnHeader styledAs={'labelSmallBold'}>
              {t('DEPARTURE')}
            </ColumnHeader>
            <ColumnHeader styledAs={'labelSmallBold'}>
              {t('ARRIVAL')}
            </ColumnHeader>
          </Head>
          {daySchedule}
        </FlexBox>
      )
    }
    return (
      <div>
        <Text
          styledAs={'bodyInterfaceBold'}
          style={{ marginBottom: theme.spacing.m }}
        >
          {t('NO-SAILINGS-THIS-DAY')}
        </Text>
        {selectedDate >= startOfToday() &&
          (nextSailingDate ? (
            <div>
              <Text styledAs={'bodyInterface'}>
                {`${t('TIMETABLE-NEXT-SAILING-ON').trim()} ${format(
                  nextSailingDate,
                  'dd MMMM yyyy',
                )}`}
              </Text>
              <LinkButton
                size="small"
                icon={<SystemIcon icon="ArrowForward" />}
                iconAlign="right"
                style={{ marginTop: theme.spacing.xs }}
                onClick={() => selectedDateChanged(nextSailingDate)}
              >
                {t('TIMETABLE-NEXT-SAILING')}
              </LinkButton>
            </div>
          ) : (
            <Text
              styledAs={'bodyInterface'}
              style={{ marginBottom: theme.spacing.xl }}
            >
              {t('TIMETABLE-BEYOND-SCHEDULE-WARNING')}
            </Text>
          ))}
      </div>
    )
  }

  return (
    <>
      <FlexBox justifySpaceBetween>{weekDays}</FlexBox>
      <FlexBox
        directionRow
        css={css`
          color: ${theme.colors.primary.main};
          justify-content: center;
          margin: ${theme.spacing.s} 0;
        `}
      >
        <Text
          styledAs={'label'}
          css={css`
            margin: 0 !important;
            text-transform: capitalize;
          `}
        >
          {formatLocalizedDate(selectedDate, 'EEEE dd ', locale)}
        </Text>
        <Text
          styledAs={'label'}
          css={css`
            margin: 0 ${theme.spacing.xxs} !important;
          `}
        >
          {formatLocalizedDate(selectedDate, ' MMMM', locale)}
        </Text>
      </FlexBox>
      <RenderDay day={selectedDay} />
    </>
  )
}

class PaxTimeTableDirectionErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error('error pax time table', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <CouldNotRetrieveData />
    }

    return <PaxTimeTableDirection {...this.props} />
  }
}

export default PaxTimeTableDirectionErrorBoundary
